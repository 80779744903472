import { request } from "../../../Services/CallAPI";

export const NEXT_TREE_ACCOUNT = async (AccountLevel, ParentAccountCode) => {
  let config = {
    method: "post",
    url: `/NextTreeAccountCode/${AccountLevel}/${ParentAccountCode}`,
  };

  return await request(config);
};
export const ACCOUNT_TREE = async (AccountLevel, ParentAccountCode) => {
  let config = {
    method: "post",
    url: `/AccountingTree`,
  };

  return await request(config);
};
export const DEFUALT_ACCOUNT_TREE = async () => {
  let config = {
    method: "post",
    url: `/DefaultAccountingTree`,
  };

  return await request(config);
};
export const ACCOUNT_TREE_DELETE = async (id) => {
  let config = {
    method: "post",
    url: `/DeleteTreeAccount/${id}`,
  };

  return await request(config);
};
export const AVAILABLE_ACCOUNTS_FOR_ACCOUNTING = async (
  AccountType,
  DebitType
) => {
  let config = {
    method: "post",
    url: `/AvailableAccountsForAccountingTree/${AccountType}/${DebitType}`,
  };

  return await request(config);
};
export const ACCOUNTS_FOR_ACCOUNTING_TRANSACTION = async (e) => {
  let config = {
    method: "post",
    url: "/NewTreeAccount",
    data: e,
  };
  return await request(config);
};
