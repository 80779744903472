import { request } from "../../../Services/CallAPI";

export const GET_STORES = async () => {
  let config = {
    method: "post",
    url: "/TrialBalances",
  };

  return await request(config);
};
export const GET_STORES_ID = async (id) => {
  let config = {
    method: "post",
    url: "/TrialBalanceAccounts/" + id,
  };

  return await request(config);
};

export const TRANSACTION = async (ob, accounts) => {
  const id = ob.ID ? ob.ID : 0;
  let x = ob;
  delete x.ID;
  let config = {
    method: "post",
    url: "/TrialBalanceTransactions",
    data: {
      Data: [
        {
          ID: id,
          TrialBalance: x,
          TrialBalanceAccounts: accounts,
        },
      ],
    },
  };
  return await request(config);
};
export const NEXT_NUMBER = async () => {
  let config = {
    method: "post",
    url: "/TrialBalanceNextNumber",
  };
  console.log(config.data);
  return await request(config);
};
export const CHECKER = async (col, val) => {
  let config = {
    method: "post",
    url: "/CheckStoreData",
    data: { CheckData: { [col]: val } },
  };
  console.log(config.data);
  return await request(config);
};

export const DELETE = async (CostTypeID) => {
  let config = {
    method: "post",
    url: `/DeleteTrialBalance/${CostTypeID}`,
  };
  console.log(config);
  return await request(config);
};
export const DELETE_ACCOUNT = async (CostTypeID) => {
  let config = {
    method: "post",
    url: `/DeleteTrialBalanceAccount/${CostTypeID}`,
  };
  console.log(config);
  return await request(config);
};
export const TRAIL_BALANCE_DROP_DOWN_DATA = async (TrialBalanceID, Level) => {
  let config = {
    method: "post",
    url: `/TrialBalanceNonAddingAccounts/${TrialBalanceID}/${Level}`,
  };
  return await request(config);
};
export const TRAIL_BALANCE_GET_ACCOUNTS = async (TrialBalanceID) => {
  let config = {
    method: "post",
    url: `/TrialBalanceAccounts/${TrialBalanceID}`,
  };
  return await request(config);
};
