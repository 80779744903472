import { LoadIndicator } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import config from "../../config";
import { useAuth } from "../../contexts/auth";
import logo from "../../Images/logo.png";
import logo2 from "../../Images/soft2 (2).png";
import Input from "../Login/Input/Input";
import queryString from "query-string";
import { SUBMIT_FORM } from "./ResetPassword.api";
const ResetPassword = (props) => {
  const { t, i18n } = useTranslation();
  const { signIn } = useAuth();

  let history = useHistory();

  const [formData, setFormData] = useState({});

  const updateFormData = useCallback((e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }, []);
  function b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob(str)));
  }
  useEffect(() => {
    let value = queryString.parse(props.location.search);
    if (value.token && value.email) {
      setFormData((prev) => ({
        ...prev,
        Serial: b64_to_utf8(value.serial),
        email: value.email,
      }));
    }
  }, [props.location.search]);
  const loginHandle = useCallback(
    async (e) => {
      e.preventDefault();
      setloading(true);
      await SUBMIT_FORM(formData)
        .then((result) => {
          history.replace("/log-in");
          notify(result.message, "done", 2000);
        })
        .catch((result) => {
          console.log(result);
          if (!result.isOk) {
            notify(result.message, "error", 2000);
          }
        })
        .finally(() => setloading(false));
    },
    [formData, history, signIn]
  );
  const [loading, setloading] = useState(false);

  return (
    <div
      style={{
        direction: i18n.language === "ar" ? "rtl" : "ltr",
        display: "flex",
      }}
      className="login d-flex flex-row align-items-center justify-content-evenly container"
    >
      <div className="w-40 login__logo">
        <img
          src={logo2}
          alt=""
          width="100%"
          className="d-inline-block align-text-top"
        />
      </div>

      <div className="centerForm notCairo">
        <div
          className="w-100 login__logo"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              maxWidth: "100px",
            }}
            src={logo}
            alt=""
            width="100%"
            className="d-inline-block align-text-top"
          />
        </div>

        <h1 className="notCairo"> {t("Reset Password")}</h1>
        <form onSubmit={loginHandle}>
          <div style={{ margin: "20px 0" }}>
            <Input
              isRequired
              label={"Password"}
              name="UserName"
              onChange={updateFormData}
              value={formData.UserName}
              type={"test"}
            />
            <Input
              isRequired
              label={"Password"}
              name="Password"
              onChange={updateFormData}
              value={formData.Password}
              type={"password"}
            />
            <Input
              isRequired
              label={"Confirm Password"}
              name="ConfirmPassword"
              onChange={updateFormData}
              value={formData.ConfirmPassword}
              type={"password"}
            />
          </div>
          <button disabled={loading} type="submit" className="login-button">
            <LoadIndicator width={"24px"} height={"24px"} visible={loading} />
            {!loading ? t("Save") : null}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
