import axios, { AxiosRequestConfig } from "axios";
import config from "../../config";

const {
  localStorageTokenKey,
  localStorageReadIdKey,
  apiEndPoint,
  apiEndPointDemo,
} = config;

let REQUEST = async (config) => {
  config.url = `${apiEndPointDemo}/Api/${config.url}`;

  axios.defaults.headers = {
    ...axios.defaults.headers,
    // domain: DomainValue(),
    "Access-Control-Allow-Origin": "*",
  };

  let data = await axios(config)
    .then((response) => {
      return JSON.stringify(response.data);
    })
    .catch(function (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem("user");
        window.location.reload();
      }
      throw error;
    });

  data = JSON.parse(data);

  return data;
};

export { REQUEST };
