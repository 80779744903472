import React, { memo } from "react";
import { useTranslation } from "react-i18next";

const TableCell = ({ color, label, value, style = "input-wrapper" }) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      className={style}
      style={{ direction: i18n.language == "ar" ? "rtl" : "ltr" }}
    >
      <div className="label">{label}</div>
      <div className={`border py-1 ${color}`} style={{ minHeight: "30px" }}>
        {value}
      </div>
    </div>
  );
};
export default memo(TableCell);
