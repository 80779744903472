export default {
  TransationsList: {
    "إيصال قبض": 1,
    "سند صرف": 2,
    "مصروفات عمومية": 3,
    "سند صرف لموظف": 4,
    "تسوية حسابين": 5,
    إيداع: 6,
    سحب: 7,
    حوالة: 8,
    "تسوية حساب مدين": 9,
    "تسوية حساب دائن": 10,
    "نقل بين الخزائن": 11,
    "قيد مباشر": 12,
    "تحويل دولار": 13,
    "شراء أصول": 14,
  },
};
