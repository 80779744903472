import React, { useCallback, useEffect, useRef, useState } from "react";
import { Popup } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";

import SimpleDropdown from "../../../../Components/SharedComponents/Dropdown Components/SimpleDropdown";
import TextBox from "../../../../Components/Inputs/TextBox";
import NumberBox from "../../../../Components/Inputs/NumberBox";
import { useTranslation } from "react-i18next";
import SelectBox from "../../../../Components/Inputs/SelectBox";
import notify from "devextreme/ui/notify";
import { CheckBox } from "devextreme-react/check-box";
import {
	NEXT_TREE_ACCOUNT,
	AVAILABLE_ACCOUNTS_FOR_ACCOUNTING,
	ACCOUNTS_FOR_ACCOUNTING_TRANSACTION,
} from "../API.AccountingTree";
import { Button } from "devextreme-react/button";
const CheckboxComponent = ({ name, Label, value, handleChange }) => {
	const { t, i18n } = useTranslation();
	return (
		<div
			className="dx-field"
			style={{
				width: "300px",
				display: "flex",
				direction: i18n.language == "ar" ? "rtl" : "ltr",
			}}
		>
			<div className="dx-field-label">{Label}</div>
			<CheckBox
				defaultValue={true}
				value={value == 1}
				name={name}
				onValueChange={(e) =>
					handleChange({ name: name, value: e ? 1 : 0 })
				}
			/>
		</div>
	);
};
const AccountingPopup = ({ visable, togglePopup, datap }) => {
	const [errors, setErrors] = useState({});
	const [list, setlist] = useState({});
	const { t, i18n } = useTranslation();
	const DefualtData = useRef({
		hesab_num: 0,
		name_hesab: "",
		DebitType: 0,
		num_table: 0,
		heasb_id: 0,
		ParentID: 0,
		state1: 0,
		state2: 0,
		state3: 0,
		state4: 0,
		day_usa: 0,
		typ: 0,
	});
	const [values, setvalues] = useState(DefualtData.current);
	const handleChange = useCallback(async ({ name, value }) => {
		setvalues((prev) => {
			return { ...prev, [name]: value };
		});
	}, []);
	const Submit = useCallback(async () => {
		await ACCOUNTS_FOR_ACCOUNTING_TRANSACTION(values)
			.then(() => {
				notify(
					{ message: t("Created successfully"), width: 600 },
					"success",
					3000
				);
				setvalues(DefualtData.current);
				togglePopup();
			})
			.catch(() => {
				notify(
					{
						message: t("error in information! try again"),
						width: 600,
					},
					"error",
					3000
				);
			});
	}, [values]);
	useEffect(() => {
		datap &&
			visable &&
			NEXT_TREE_ACCOUNT(datap.AccountLevel, datap.ParentID)
				.then((res) => {
					setvalues({
						...values,
						hesab_num: res.NextCode,
						ParentID: datap.ParentID,
					});
				})
				.catch(() => {
					//  setvalues(DefualtData.current);
				});
	}, [datap, visable]);
	useEffect(async () => {
		setlist(
			await AVAILABLE_ACCOUNTS_FOR_ACCOUNTING(
				values.num_table,
				values.DebitType
			)
		);
	}, [values.DebitType, values.num_table]);
	const cols = useRef("col-lg-6 col-md-6 col-sm-12");
	return (
		<Popup
			height={450}
			visible={visable}
			onHiding={() => {
				setvalues(DefualtData.current);
				togglePopup();
			}}
		>
			<ScrollView showScrollbar="onHover">
				<form
					className="row"
					style={{ display: "flex", justifyContent: "center" }}
				>
					{" "}
					<div className={cols.current}>
						<TextBox
							label={t("Number")}
							value={values.hesab_num}
							name="hesab_num"
							handleChange={handleChange}
							disabled={true}
						/>
					</div>
					<div className={cols.current}>
						<TextBox
							label={t("Name")}
							value={values["name_hesab"]}
							name="name_hesab"
							handleChange={handleChange}
							required={false}
							validationErrorMessage={errors.name_hesab}
						/>
					</div>
					<div className="col-12 row divided-form">
						<h3 style={{ padding: "20px 20px" }}>
							{t("Fetch from an active account")}
						</h3>
						<div className={cols.current}>
							<SelectBox
								label={t("Indebtedness Type")}
								dataSource={[
									{ id: 0, name: "مدين " },
									{ id: 1, name: "دائن" },
								]}
								value={values.DebitType}
								name="DebitType"
								handleChange={handleChange}
								required={false}
								validationErrorMessage={t(errors.branch)}
							/>
						</div>
						<div className={cols.current}>
							<SelectBox
								label={t("Level")}
								dataSource={[
									{ id: 1983, name: "الكـــــــــــــل" },
									{ id: 100, name: "العمــــــــــلاء" },
									{ id: 102, name: "المصـــــــارف" },
									{ id: 101, name: "المصروفــــات" },
									{ id: 105, name: "الأصــــــــــول" },
									{ id: 104, name: "الموظفـــــــين" },
									{ id: 104, name: "أخــــــــــــرى" },
								]}
								value={values.num_table}
								name="num_table"
								handleChange={handleChange}
								required={false}
								validationErrorMessage={t(errors.branch)}
							/>
						</div>
						<div className={cols.current}>
							<SelectBox
								label={t("Account")}
								dataSource={list}
								value={values.heasb_id}
								name="heasb_id"
								handleChange={handleChange}
								required={false}
								validationErrorMessage={t(errors.heasb_id)}
							/>
						</div>
					</div>
					<div className={cols.current}>
						<CheckboxComponent
							value={values.state1}
							name={"state1"}
							Label={t("Profits and losses")}
							handleChange={handleChange}
						/>
					</div>
					<div className={cols.current}>
						<CheckboxComponent
							value={values.state2}
							name={"state2"}
							handleChange={handleChange}
							Label={t("General budget")}
						/>
					</div>
					<div className={cols.current}>
						<CheckboxComponent
							value={values.state3}
							name={"state3"}
							handleChange={handleChange}
							Label={t("Income list")}
						/>
					</div>
					<div className={cols.current}>
						<CheckboxComponent
							value={values.state4}
							name={"state4"}
							handleChange={handleChange}
							Label={t("Trial Balance")}
						/>
					</div>
					<div className={cols.current}>
						<CheckboxComponent
							value={values.day_usa}
							name={"day_usa"}
							handleChange={handleChange}
							Label={t("American diary")}
						/>
					</div>
					<div className={cols.current}>
						<SelectBox
							label={t("Indebtedness Type")}
							dataSource={[
								{ id: 0, name: "مدين " },
								{ id: 1, name: "دائن" },
							]}
							value={values.typ}
							name="typ"
							handleChange={handleChange}
							required={false}
							validationErrorMessage={t(errors.typ)}
						/>
					</div>
					<div className="col-12">
						<Button
							width={"100%"}
							text={t("Add")}
							type="normal"
							onClick={Submit}
							stylingMode="contained"
						/>
					</div>
				</form>
			</ScrollView>
		</Popup>
	);
};

export default React.memo(AccountingPopup);
