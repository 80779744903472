import React, { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import TableCell from "../../../../Components/Items/tableCell";
import notify from "devextreme/ui/notify";

import {
  ACCOUNT_TREE,
  ACCOUNT_TREE_DELETE,
  AVAILABLE_ACCOUNTS_FOR_ACCOUNTING,
  DEFUALT_ACCOUNT_TREE,
  NEXT_TREE_ACCOUNT,
} from "../API.AccountingTree";

import { Button } from "devextreme-react/button";
import { useCallback } from "react";
import AccountingPopUp from "./AccountingPopUp";
import TreeListTable from "../../../../Components/TreeListTable/TreeListTable";
function AccountingTreeDiv() {
  const { t, i18n } = useTranslation();
  const [data, setdata] = useState();
  const [PopUpData, setPopUpData] = useState({});
  const [visiable, setvisiable] = useState();
  useEffect(async () => {
    setdata(await ACCOUNT_TREE());
  }, []);
  const cols = useRef([
    {
      col: "hesab_num",
      caption: "رقم الاحتساب ",
      captionEn: "Account Number",
    },
    {
      col: "name_hesab",
      caption: "اسم الاحتساب",
      captionEn: "Account Name",
    },
  ]);
  const AddFunction = useCallback(async (e) => {
    if (e != null) {
      setPopUpData(e.row.data);
    } else {
      setPopUpData({ AccountLevel: 0, ParentID: "Non" });
    }

    setvisiable(true);
    setdata(await ACCOUNT_TREE());
  }, []);
  const ClosePopUp = useCallback(async () => {
    setvisiable(false);
    setPopUpData(null);

    setdata(await ACCOUNT_TREE());
  }, []);
  const DefualtTree = useCallback(async () => {
    setdata([]);
    await DEFUALT_ACCOUNT_TREE().then(async () => {
      setdata(await ACCOUNT_TREE());
      notify(
        { message: t("Created successfully"), width: 600 },
        "success",
        3000
      );
    });
  }, []);
  const OnDelete = useCallback(async (e) => {
    await ACCOUNT_TREE_DELETE(e.data.id)
      .then(() => {
        notify(
          { message: t("Deleted Successfully"), width: 600 },
          "success",
          3000
        );
      })
      .catch(() => {
        e.cancel = true;
        notify(
          {
            message: t("error in information! try again"),
            width: 600,
          },
          "error",
          3000
        );
      });
  }, []);

  return (
    <div
      className="container-xxl card p-3"
      style={{ direction: i18n.language == "en" ? "ltr" : "rtl" }}
    >
      <div className="col-12">
        <h2 style={{ textAlign: "center", padding: "20px 0" }}>
          {t("Accounting tree")}
        </h2>
      </div>
      <AccountingPopUp
        togglePopup={ClosePopUp}
        visable={visiable}
        datap={PopUpData}
      />
      {/* 
      <div>
        <TableCell label={t("Account name")} value={0} />
      </div>
        <div className="col">
          <TableCell label={t("Creditor")} value={0} />
        </div>
        <div className="col">
          <TableCell label={t("Debit")} value={0} />
        </div>
        <div className="col">
          <TableCell label={t("Balance")} value={0} />
        </div>
      </div>
     */}{" "}
      <div className="row" style={{ width: "50%" }}>
        <div className="col">
          <Button
            width={"100%"}
            text={t("Default Creation")}
            type="normal"
            stylingMode="contained"
            onClick={DefualtTree}
          />
        </div>
        <div className="col">
          <Button
            width={"100%"}
            text={t("Add")}
            type="normal"
            stylingMode="contained"
            onClick={() => AddFunction(null)}
          />
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <TreeListTable
          colAttributes={cols.current}
          allowDeleting
          keyExpr={"TreeAccountID"}
          parentIdExpr={"ParentID"}
          buttonfunction={AddFunction}
          onRowRemoving={OnDelete}
          dataSource={data}
        />
      </div>
    </div>
  );
}

export default AccountingTreeDiv;
