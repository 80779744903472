import React, { useState, useEffect, useCallback, useMemo } from "react";
import TextBox from "../../../Components/Inputs/TextBox";
import NumberBox from "../../../Components/Inputs/NumberBox";
import ButtonRow from "../../../Components/SharedComponents/buttonsRow";
import { Popup } from "devextreme-react/popup";
import EditDelete from "../../../Components/SharedComponents/EditDelete1";
import { validateForm } from "../../../Services/services";
import Joi from "joi";
import notify from "devextreme/ui/notify";
import {
  GET_STORES,
  TRANSACTION,
  NEXT_NUMBER,
  DELETE,
  TRAIL_BALANCE_DROP_DOWN_DATA,
  DELETE_ACCOUNT,
  TRAIL_BALANCE_GET_ACCOUNTS,
} from "./Api.TrailBalance";
import { useTranslation } from "react-i18next";
import DateTime from "../../../Components/Inputs/DateTime";
import SelectBox from "../../../Components/Inputs/SelectBox";
import InputTableEdit from "../../../Components/SharedComponents/Tables Components/InputTableEdit";
import { useRef } from "react";
function TrialBalance() {
  const { t, i18n } = useTranslation();
  const [errors, setErrors] = useState({});
  const [dailog, setdialog] = useState(false);
  const [data, setData] = useState([]);
  const [Tabledata, setTabledata] = useState([]);

  const [disapleInput, setdisapleInput] = useState(false);
  const [DropDownData, setDropDownData] = useState([]);
  const DefualtData = useRef({
    Number: 0,
    CreationDate: new Date(),
    TrialBalanceName: "",
    Note: "",
    AccountLevel: 0,
  });
  const [values, setvalues] = useState(DefualtData.current);
  let levels = useRef([
    { id: 0, name: "الأول" },
    { id: 1, name: "الثاني" },
    { id: 2, name: "الثالث" },
    { id: 3, name: "الرابع" },
  ]);
  const onUndo = useCallback(async () => {
    setvalues(DefualtData.current);
    setTabledata([]);
    setErrors({});
    setdisapleInput(true);
  }, []);
  const [editDeleteStatus, setEditDeleteStatus] = useState("");
  const schema = useMemo(() => {
    return {
      Number: Joi.number()
        .greater(0)
        .required()
        .messages({
          "any.required": t("Number is Required "),
          "number.greater": t("This number must be greater than zero."),
        }),

      TrialBalanceName: Joi.string()
        .required()
        .messages({
          "any.required": t("This Input is Required"),
        }),
    };
  }, []);
  const columnAttributes = useMemo(() => {
    return [
      {
        caption: "الرقم",
        captionEn: "Number",
        field: "Number",
      },
      {
        caption: "الاسم",
        field: "TrialBalanceName",
        captionEn: "Name",
      },
      {
        caption: "التاريخ",
        field: "CreationDate",
        captionEn: "Date",
      },
    ];
  }, []);

  let submit = async (e) => {
    e.preventDefault();

    let { Number, TrialBalanceName, AccountLevel } = values;
    let err = validateForm({ Number, TrialBalanceName, AccountLevel }, schema);

    if (Object.keys(err).length != 0) {
      setErrors(err);
      notify(
        { message: t("Continue the missing data"), width: 600 },
        "error",
        3000
      );
      return;
    }
    await TRANSACTION(values, Tabledata)
      .then(async () => {
        onNew();
        notify(
          { message: t("Saved Successfully"), width: 600 },
          "success",
          3000
        );
      })
      .catch(
        ({
          response: {
            data: { Errors },
          },
        }) => {
          console.log(Errors);

          let responseErrors = {};
          if (Errors) {
            Errors.forEach(({ Column, Error }) => {
              responseErrors = { ...responseErrors, [Column]: Error };
            });
          }
          setErrors(responseErrors);
          notify({ message: t("Failed Try again"), width: 450 }, "error", 2000);
        }
      );
  };

  const handleChange = useCallback(async ({ name, value }) => {
    setvalues((prev) => {
      return { ...prev, [name]: value };
    });
    if (name == "AccountLevel") {
      setDropDownData(
        await TRAIL_BALANCE_DROP_DOWN_DATA(values.ID ? values.ID : 0, value)
      );
    }
  }, []);
  useEffect(() => {
    onNew();
  }, []);
  const onUpdateDelete = useCallback(
    async (title) => {
      setData(await GET_STORES());
      setEditDeleteStatus(title);
      setdialog(true);
      setdisapleInput(false);
    },
    [data, editDeleteStatus]
  );
  const onNew = useCallback(async () => {
    setvalues({
      ...DefualtData.current,
      Number: (await NEXT_NUMBER()).NextNumber,
    });
    setTabledata([]);
    setdisapleInput(false);
    setErrors([]);
    setDropDownData(
      await TRAIL_BALANCE_DROP_DOWN_DATA(0, levels.current[0].id)
    );
  }, [NEXT_NUMBER, setvalues]);
  let onDeleteFun = useCallback(async (id) => {
    onNew();
    return await DELETE(id);
  }, []);

  let ClosePopUp = useCallback(async (id) => {
    setdialog(false);
  }, []);
  let ondeleteRow = async (e) => {
    //delete from InvoicesCostsCosts
    if (e.data?.ID)
      await DELETE_ACCOUNT(e.data?.ID)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          e.cancel = true;
        });
  };
  return (
    <div dir={i18n.language == "en" ? "ltr" : "rtl"}>
      <form
        onSubmit={submit}
        className="row Discountmain container-xxl card p-3"
      >
        <h1 style={{ width: "100%", textAlign: "center", padding: "2%" }}>
          {t("Trial Balance")}
        </h1>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 0,
            paddingTop: "20px",
          }}
        >
          <div className="col-lg-6 col-md-6 col-sm-12">
            <NumberBox
              label={t("Number")}
              value={values.Number}
              name="Number"
              handleChange={handleChange}
              validationErrorMessage={errors.Number}
              required={false}
              disabled={disapleInput || values.ID}
              nonNegative
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <DateTime
              label={t("Date")}
              value={values["CreationDate"]}
              name="CreationDate"
              handleChange={handleChange}
              required={true}
              required={false}
              disabled={disapleInput || values.ID}
              validationErrorMessage={t(errors.CreationDate)}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <TextBox
              label={t("Name")}
              value={values["TrialBalanceName"]}
              name="TrialBalanceName"
              handleChange={(e) => handleChange(e)}
              required={false}
              disabled={disapleInput || values.ID}
              validationErrorMessage={errors.TrialBalanceName}
              nonNegative
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <TextBox
              label={t("Note")}
              value={values["Note"]}
              name="Note"
              disabled={disapleInput || values.ID}
              handleChange={handleChange}
              required={false}
              validationErrorMessage={errors.Note}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            {" "}
            <SelectBox
              label={t("Level")}
              dataSource={levels.current}
              value={values.AccountLevel}
              name="AccountLevel"
              disabled={disapleInput || values.ID}
              handleChange={handleChange}
              required={false}
              validationErrorMessage={t(errors.branch)}
            />
          </div>
        </div>

        <div style={{ padding: "50px 0" }}>
          <InputTableEdit
            Uicon
            height="500px"
            dataSource={Tabledata}
            colAttributes={[
              {
                caption: "الحساب",
                captionEn: "Account",
                field: "AccountID",
                data: DropDownData,
                keyname: "name_hesab",
                value: "AccountID",
                required: true,
              },
            ]}
            height={"300px"}
            canDelete={true}
            onRowInserting={(e) => {
              //e.cancel = true;
              setTabledata(
                Tabledata.map((ele) => {
                  return { AccountID: ele.AccountID };
                })
              );
            }}
            canAdd
            filterRow={false}
            onRowRemoving={ondeleteRow}
          />
        </div>
        <ButtonRow
          onNew={onNew}
          onCopy={null}
          onEdit={onUpdateDelete}
          onDelete={onUpdateDelete}
          isSimilar={false}
          onUndo={onUndo}
          isExit={false}
        />
        <Popup
          maxWidth={1000}
          title={editDeleteStatus == "edit" ? t("Edit") : t("Delete")}
          minWidth={150}
          minHeight={500}
          showTitle={true}
          dragEnabled={false}
          closeOnOutsideClick={true}
          visible={dailog}
          onHiding={() => setdialog(false)}
        >
          <EditDelete
            data={data}
            columnAttributes={columnAttributes}
            deleteItem={onDeleteFun}
            close={ClosePopUp}
            getEditData={async (e) => {
              setvalues(e);
              console.log(e);
              setTabledata(
                (await TRAIL_BALANCE_GET_ACCOUNTS(e.ID)).map((ele) => {
                  return { ID: ele.AccountID, ...ele };
                })
              );
            }}
            editDeleteStatus={editDeleteStatus}
          />
        </Popup>
      </form>
    </div>
  );
}

export default TrialBalance;
