import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Input from "../../Input/Input";
import Modal from "../../Modal/Modal";
import ReCAPTCHA from "react-google-recaptcha";

import "./RequestDemoModal.css";
import { SUBMIT_FORM } from "./RequestDemoModel.Api";
import { LoadIndicator } from "devextreme-react";

const RequestDemoModal = ({ visable, handleClose, id }) => {
  const { t, i18n } = useTranslation();
  const defualtvalues = useRef({
    firstName: "",
    industry: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    lastName: "",
    productId: 0,
    webapp: 1,
  });

  const [values, setvalues] = useState(defualtvalues.current);
  const [captcha, setcaptcha] = useState(false);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    setvalues({ ...defualtvalues.current, productId: parseInt(id) });
    //setcaptcha(false);
  }, [visable]);

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);
    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  const HandleChange = useCallback((e) => {
    setvalues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }, []);

  //**blob to dataURL**
  function blobToDataURL(blob, callback) {
    var a = new FileReader();
    a.readAsDataURL(blob);
    a.onload = function (e) {
      console.log(e);
      callback(e.target.result);
    };
  }

  let submit = useCallback(
    async (e) => {
      e.preventDefault();
      setloading(true);
      await SUBMIT_FORM(values)
        .then((res) => {
          setvalues({
            ...defualtvalues.current,
            productId: parseInt(id),
          });
          setloading(false);
          notify(
            {
              message: t(
                "Send Successfully and we will connect with you as soon as possible"
              ),
              width: 600,
            },
            "success",
            6000
          );
          handleClose();
        })
        .catch((e) => {
          console.log(e);
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [values, id, t, handleClose]
  );

  return (
    <div
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
      }}
    >
      <Modal
        title={t("Request Demo")}
        visable={visable}
        closeModalHandler={handleClose}
      >
        <form onSubmit={submit}>
          <div
            className="modal__body"
            style={{ color: "#3a7498", margin: "10px" }}
          >
            <h4 style={{ marginBottom: "20px" }}>
              {t("Thank you for your interest in receiving a demo ")}
            </h4>

            <div className="row px-5 py-2">
              <div className="col-md-6 col-12">
                <Input
                  label={"First Name"}
                  isRequired={true}
                  type={"text"}
                  name="firstName"
                  onChange={HandleChange}
                  value={values.firstName}
                />
              </div>
              <div className="col-md-6 col-12">
                <Input
                  label={"Last Name"}
                  isRequired={true}
                  type={"text"}
                  name="lastName"
                  onChange={HandleChange}
                  value={values.lastName}
                />
              </div>
              <div className="col-md-6 col-12">
                <Input
                  label={"Phone Number"}
                  isRequired={true}
                  type={"text"}
                  onChange={HandleChange}
                  name="phoneNumber"
                  value={values.phoneNumber}
                />
              </div>
              <div className="col-md-6 col-12">
                <Input
                  label={"E-mail"}
                  isRequired={true}
                  type={"email"}
                  onChange={HandleChange}
                  name={"email"}
                  value={values.email}
                />
              </div>

              <div className="col-md-6 col-12">
                <Input
                  isRequired
                  label={"Company Name"}
                  name="companyName"
                  value={values.companyName}
                  onChange={HandleChange}
                  type={"text"}
                />
              </div>
              <div className="col-md-6 col-12">
                <Input
                  isRequired
                  label={"Industry"}
                  name="industry"
                  onChange={HandleChange}
                  value={values.industry}
                  type={"text"}
                />
              </div>
            </div>
          </div>
          <div className="captcha">
            <ReCAPTCHA
              sitekey="6Lf-y58gAAAAAD61mDrZi9PJaUZX917dDTVe4H28"
              onChange={(e) => setcaptcha(true)}
            />
          </div>

          <div className="w-100 d-flex justify-content-center">
            <button
              className="btn signInButton"
              disabled={!captcha || loading}
              type={"submit"}
            >
              <LoadIndicator width={"24px"} height={"24px"} visible={loading} />
              {!loading ? t("Send") : null}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default RequestDemoModal;
