import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectBox from "../../../Components/Inputs/SelectBox";

import {
  selectCategories,
  fetchCategories,
} from "../../../Store/Items/CategoriesSlice";
import { TRAIL_BALANCE_REPORT, TRIAL_BALANCE } from "./API.TrialBalanceReport";
import ColumnsTable from "../../../Components/SharedComponents/Tables Components/ColumnsTable.jsx";
import { useTranslation } from "react-i18next";
import { DateTime } from "../../../Components/Inputs";
import { useRef } from "react";
function TrailBalanceReport(props) {
  let categories = useSelector(selectCategories);
  const { t, i18n } = useTranslation();
  const [TrialBalance, setTrialBalance] = useState([]);
  const [Data, setData] = useState([]);
  let dispatch = useDispatch();
  function onValueChanged(e) {
    let dateType = new Date(e);
    let month = (parseInt(dateType.getMonth()) + 1).toString();
    let finalFormat = (
      month +
      "/" +
      dateType.getDate() +
      "/" +
      dateType.getFullYear()
    ).toString();

    return finalFormat;
  }
  const tabCol = useMemo(() => {
    return [
      { caption: "الحساب", captionEn: "Account", field: "name_hesab" },

      {
        caption: "الرصيد الافتتاحي",
        captionEn: "Initial Balance",
        incol: [
          {
            caption: "المدين",
            captionEn: "debtor",
            field: "First_Debit",
          },
          {
            caption: "الدائن",
            captionEn: "creditor",
            field: "FirstPayable",
          },
        ],
      },
      {
        caption: "الرصيد خلال الفتره",
        captionEn: "Balance during the period",
        incol: [
          {
            caption: "المدين",
            captionEn: "debtor",
            field: "DebitInTime",
          },
          {
            caption: "الدائن",
            captionEn: "creditor",
            field: "PayableInTime",
          },
        ],
      },
      {
        caption: "الرصيد الحالي",
        captionEn: "current balance",
        incol: [
          {
            caption: "المدين",
            captionEn: "debtor",
            field: "DebitNow",
          },
          {
            caption: "الدائن",
            captionEn: "creditor",
            field: "PayableNow",
          },
        ],
      },
    ];
  }, []);
  let [values, setvalues] = useState({
    FromDate: new Date(),
    ToDate: new Date(),
    TrialBalanceID: 0,
  });

  let handleChange = useCallback(({ name, value }) => {
    setvalues((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  useEffect(async () => {
    setTrialBalance(await TRIAL_BALANCE());
    setvalues({
      ...values,
      FromDate: onValueChanged(new Date()),
      ToDate: onValueChanged(new Date()),
    });
    console.log(onValueChanged(new Date()));
  }, []);
  useEffect(async () => {
    if (values.TrialBalanceID > 0) setData(await TRAIL_BALANCE_REPORT(values));
  }, [values]);
  return (
    <div
      className="row container-xxl card p-3"
      dir="auto"
      style={{ display: "flex", justifyContent: "center", margin: 0 }}
    >
      <h1 style={{ width: "100%", textAlign: "center", paddingBottom: "50px" }}>
        {t("Trial Balance Report")}
      </h1>
      <div
        className="row"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="col-12 col-md-6 col-lg-4">
          <SelectBox
            label={t("According to Category")}
            dataSource={TrialBalance}
            value={values.TrialBalanceID}
            name="TrialBalanceID"
            handleChange={handleChange}
            keys={{ id: "ID", name: "TrialBalanceName" }}
            required={false}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <DateTime
            label={t("From")}
            value={values.FromDate}
            name="FromDate"
            handleChange={handleChange}
            required={true}
            required={false}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <DateTime
            label={t("To")}
            value={values.ToDate}
            name="ToDate"
            handleChange={handleChange}
            required={true}
            required={false}
          />
        </div>
      </div>
      <div>
        <ColumnsTable dataSource={Data} height="400px" colAttributes={tabCol} />
      </div>
    </div>
  );
}
export default TrailBalanceReport;
