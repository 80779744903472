import React, { useCallback, useState } from "react";
import { LOGIN } from "./API.Login";
import config from "../../config";
import ReCAPTCHA from "react-google-recaptcha";
import "./Login.css";
import logo from "../../Images/logo.png";
import logo2 from "../../Images/soft2 (2).png";
import { setWithExpiry } from "../../Services/LocalStorageService";
import { useHistory, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import notify from "devextreme/ui/notify";
import { useAuth } from "../../contexts/auth";
import RequestDemoModal from "./RequestDemoModal/user/RequestDemoModal";
import { LoadIndicator } from "devextreme-react";
import ForgetPassword from "./ForgetPassword/ForgetPassword";
import Input from "./Input/Input";

const {
  localStorageTokenKey,
  localStorageReadIdKey,
  localStorageMultiCurrencyKey,
  localStorageCurrencyKey,
} = config;

const Login = () => {
  const { t, i18n } = useTranslation();
  const { signIn } = useAuth();

  let history = useHistory();

  const [formData, setFormData] = useState({});

  const updateFormData = useCallback((e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }, []);
  const handleRequestDemoClick = useCallback(() => {
    setShowRequestDemoModal(true);
  }, []);
  const handleForgetDemoClick = useCallback(() => {
    setShowForgetModal(true);
  }, []);
  const loginHandle = useCallback(
    async (e) => {
      e.preventDefault();
      setloading(true);
      signIn(formData.username, formData.password, formData.serial)
        .then((result) => {
          console.log(result);
          if (!result.isOk) {
            console.log(1);
            notify(result.message, "error", 2000);
          }
          setloading(false);
          history.replace("/home");
        })
        .catch((result) => {
          console.log(result);
          if (!result.isOk) {
            console.log(1);
            notify(result.message, "error", 2000);
          }
          setloading(false);
        });
    },
    [formData.password, formData.serial, formData.username, history, signIn]
  );
  const [loading, setloading] = useState(false);
  const [showRequestDemoModal, setShowRequestDemoModal] = useState(false);
  const [showForgetModal, setShowForgetModal] = useState(false);
  const handleRequestDemoClose = useCallback(() => {
    setShowRequestDemoModal(false);
  }, []);
  const handleForgetDemoClose = useCallback(() => {
    setShowForgetModal(false);
  }, []);
  return (
    <div
      style={{
        direction: i18n.language === "ar" ? "rtl" : "ltr",
        display: "flex",
      }}
      className="login d-flex flex-row align-items-center justify-content-evenly container"
    >
      <RequestDemoModal
        id={0}
        visable={showRequestDemoModal}
        handleClose={handleRequestDemoClose}
      />
      <ForgetPassword
        visable={showForgetModal}
        handleClose={handleForgetDemoClose}
      />
      <div className="w-40 login__logo">
        <img
          src={logo2}
          alt=""
          width="100%"
          className="d-inline-block align-text-top"
        />
      </div>

      <div className="centerForm notCairo">
        <div
          className="w-100 login__logo"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              maxWidth: "100px",
            }}
            src={logo}
            alt=""
            width="100%"
            className="d-inline-block align-text-top"
          />
        </div>
        <div className="text-center p-3 buy__section">
          <div className="pass" onClick={handleRequestDemoClick}>
            {t("Test product for 7 day trial!")}
          </div>
        </div>
        <h1 className="notCairo"> {t("Log in")}</h1>
        <form onSubmit={loginHandle} className="notCairo">
          <div style={{ margin: "30px 0" }}>
            <Input
              isRequired
              label={"Username"}
              name="username"
              onChange={updateFormData}
              value={formData.username}
              type={"text"}
            />

            <Input
              isRequired
              label={"Password"}
              name="password"
              onChange={updateFormData}
              value={formData.password}
              type={"password"}
            />
            <Input
              isRequired
              label={"Serial Number"}
              name="serial"
              onChange={updateFormData}
              value={formData.serial}
              type={"serial"}
            />
          </div>
          {
            <div
              style={{ padding: "10px 0" }}
              className="pass"
              onClick={handleForgetDemoClick}
            >
              {t("Forgot Password ?")}
            </div>
          }
          <button disabled={loading} type="submit" className="login-button">
            <LoadIndicator width={"24px"} height={"24px"} visible={loading} />
            {!loading ? t("Log in") : null}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
