import { request } from "../../../Services/CallAPI";

export const TRAIL_BALANCE_REPORT = async (e) => {
  let config = {
    method: "post",
    url: `/AccountingTreeReport/${e.TrialBalanceID}`,
    data: e,
  };
  return await request(config);
};
export const TRIAL_BALANCE = async () => {
  let config = {
    method: "post",
    url: "/TrialBalances",
  };

  return await request(config);
};
