import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest } from '../api/auth';
import { LOGIN } from '../Pages/Login/API.Login';

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.data);
      }
      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (username, password, serial) => {
    let result = {
      isOk: false,
      message: "",
      data: ""
    }

    return LOGIN(username, password, serial)
      .then(({ Read_ID, Token }) => {
        result = {
          isOk: true,
          data: {
            Read_id: Read_ID,
            Token: Token
          }
        }

        setUser(result.data);

        localStorage.setItem("data", JSON.stringify(result.data))

        return result;
      })
      .catch(err => {
        result.message = err.response?.data?.error ?? "Authentication failed."
        return result
      })
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("data")
    setUser();
  }, []);


  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />
  );
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
