import React, { useCallback, useEffect } from "react";
import { Button } from "devextreme-react/button";
import { REPORTS } from "./Api";
import { useTranslation } from "react-i18next";

export default function PrintButton({
  param,
  data,
  disabled = false,
  onclickbutton = () => {},
  width = "100%",
}) {
  const { t, i18n } = useTranslation();
  console.log(param);
  let PDFopen = async () => {
    onclickbutton();
    await REPORTS(param, data)
      .then((res) => {
        let pdf = "data:application/pdf;base64," + res;
        window
          .open("", "", "width=800,height=600,left=200,top=200")
          ?.document.write(
            " <iframe  src='" + pdf + "' width='100%' height='100%' />"
          );
      })
      .then((err) => {
        console.log(err);
      });
  };
  return (
    <Button
      className="mx-1 buttonStyle"
      stylingMode="outlined"
      width={width}
      text={t("Print")}
      type="default"
      disabled={disabled}
      icon="fas fa-print"
      rtlEnabled={true}
      onClick={PDFopen}
    />
  );
}
