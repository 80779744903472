import React from "react";
import AccountingTreeDiv from "./Components/AccountingTree";
import "./index.css";

const AccountingTree = () => {
  return (
    <div className="row">
      <AccountingTreeDiv />
    </div>
  );
};

export default AccountingTree;
