import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import ReCAPTCHA from "react-google-recaptcha";

import { SUBMIT_FORM } from "./ForgetPassword.Api";
import { LoadIndicator } from "devextreme-react";
import Input from "../Input/Input";
import Modal from "../Modal/Modal";

const ForgetPassword = ({ visable, handleClose, id }) => {
  const { t, i18n } = useTranslation();

  const [values, setvalues] = useState({ Serial: null });
  const [captcha, setcaptcha] = useState(false);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    setvalues({ Serial: null });
  }, [visable]);

  const HandleChange = useCallback((e) => {
    console.log({ [e.target.name]: e.target.value });
    setvalues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);

  let submit = useCallback(
    async (e) => {
      e.preventDefault();
      setloading(true);
      console.log(values);
      await SUBMIT_FORM(values.Serial)
        .then((res) => {
          setvalues({
            Serial: null,
          });
          setloading(false);
          notify(
            {
              message: t("Reset Password mail has been send"),
              width: 600,
            },
            "success",
            6000
          );
          handleClose();
        })
        .catch((e) =>
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000)
        )
        .finally(() => setloading(false));
    },
    [values, id, t, handleClose]
  );

  return (
    <div
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
      }}
    >
      <Modal
        title={t("Forget Password")}
        visable={visable}
        closeModalHandler={handleClose}
        maxWidth={"400px"}
      >
        <form onSubmit={submit}>
          <div
            className="modal__body"
            style={{ color: "#3a7498", margin: "10px" }}
          >
            <h4 style={{ marginBottom: "20px" }}>
              {t("Thank you for using our service")}
            </h4>

            <div className="row px-5 py-2">
              <div>
                <Input
                  isRequired
                  label={"Serial Number"}
                  name="Serial"
                  onChange={HandleChange}
                  value={values.Serial}
                  type={"number"}
                />
              </div>
            </div>
          </div>
          <div className="captcha">
            <ReCAPTCHA
              sitekey="6Lf-y58gAAAAAD61mDrZi9PJaUZX917dDTVe4H28"
              onChange={(e) => setcaptcha(true)}
            />
          </div>

          <div className="w-100 d-flex justify-content-center">
            <button
              className="btn signInButton"
              disabled={!captcha || loading}
              type={"submit"}
            >
              <LoadIndicator width={"24px"} height={"24px"} visible={loading} />
              {!loading ? t("Send") : null}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ForgetPassword;
