import React, { useEffect, useState, useCallback } from "react";
import { SelectBox } from "../../../Components/Inputs";
import { GET_MAIN_CATEGORIES } from "../../../Services/ApiServices/ItemsAPI";
import ItemsValidityTable from "./Components/ItemsValidityTable/ItemsValidityTable";
import { useTranslation } from "react-i18next";
import PrintButton from "../../../Components/PrintButton/PrintButton";
const ItemsValidity = () => {
  const [categories, setCategories] = useState([]);
  const { t, i18n } = useTranslation();
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);

  useEffect(() => {
    GET_MAIN_CATEGORIES().then(({ MainCategory }) => {
      MainCategory &&
        setCategories([{ id: 0, name: t("All Categories") }, ...MainCategory]);
    });
  }, []);
  return (
    <>
      <h1 className="invoiceName">{t("Validity of Items")}</h1>
      <div className="container rtlContainer">
        <div className="card p-3">
          <div className="row">
            <div className="col-4">
              <SelectBox
                label={t("Categorize")}
                dataSource={categories}
                value={selectedCategoryId}
                handleChange={(e) => setSelectedCategoryId(e.value)}
                name="typ_id"
              />
            </div>
            <div className="col-4">
              <PrintButton
                param={
                  window.location.href.split("/")[
                    window.location.href.split("/").length - 1
                  ]
                }
                data={{ categoryId: selectedCategoryId }}
              />
            </div>
          </div>
          <div className="row my-2">
            <ItemsValidityTable selectedCategoryId={selectedCategoryId} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemsValidity;
