import axios from "axios";
import { REQUEST } from "../Login/callAPI";

export const SUBMIT_FORM = async (e) => {
  return await REQUEST({
    method: "post",
    url: "RequestDemo/forget-password-web",
    // responseType: "blob",
    data: e,
  });
};
