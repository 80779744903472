import React, { useState, useEffect } from "react";
import TableCell from "../../Components/Items/tableCell";
import { Column } from "devextreme-react/data-grid";
import { DataGrid } from "devextreme-react/data-grid";
import { useTranslation } from "react-i18next";
import { Button } from "devextreme-react/button";
import {
    QUICK_ITEM_INFO,
    ITEM_MINMUM_LIMIT_QUANTITY,
} from "../../Services/ApiServices/ItemsAPI";

import { GET_REPORT_ITEM_STORES_QUANTITY } from "../../Services/ApiServices/General/ReportsAPI";
import { NumberBox } from "devextreme-react";
import UpperLabel from "./../../Components/Items/upperLabel";

import { useSelector } from "react-redux";
import { selectItem } from "../../Store/Items/ItemsSlice";
import { useParams } from "react-router";

const ItemDetails = () => {
    const { id } = useParams();
    let [item, setItem] = useState({
        SaleTransactions: [],
    });
    let [itemStores, setItemStores] = useState([]);
    let [minimum, setMinimum] = useState(0);
    let [store, setStore] = useState({});
    let { itemId } = useSelector(selectItem);
    const { t, i18n } = useTranslation();
    useEffect(async () => {
        let data = await QUICK_ITEM_INFO(id);
        setItem(data);

        let stores = await GET_REPORT_ITEM_STORES_QUANTITY(id);
        setItemStores(stores);
        if (stores.length > 0) {
            setStore(stores[0]);
            setMinimum(parseFloat(stores[0].minimu));
        }
    }, []);

    let handleChange = async ({ value }) => {
        setMinimum(value);

        try {
        } catch (error) {
            console.log(error);
        }
    };
    const submitNumber = async () => {
        if (minimum) {
            await ITEM_MINMUM_LIMIT_QUANTITY({
                StoreID: store.docno,
                MinimumLimitQuantity: minimum,
                Location: store.locate,
                ItemID: item.id,
            }).then(() => {
                setItemStores(
                    itemStores.map((it) =>
                        it.docno === store.docno ? { ...it, _0: minimum } : { ...it }
                    )
                );
            });
        }
    };

    return (
        <div className="container-xxl card p-3">
            <UpperLabel label={t("Item Details")} />

            <div className="border mx-3 p-3">
                <div className="triple">
                    <TableCell label={t("Number")} value={item.item_no} />
                    <TableCell label={t("Name")} value={item.item_name} />
                    <TableCell label={t("Code Type")} value={item.code_no} />
                    <TableCell label={t("Categorize")} value={item.description} />
                    <TableCell label={t("Commercial Number")} value={item.PARCODE1} />
                    <TableCell label={t("English Name")} value={item.e_name} />
                </div>

                <div className="four-in-row mt-3">
                    <TableCell label={t("Long")} value={item.tol} style="four " />
                    <TableCell label={t("Offer")} value={item.ord} style="four" />
                    <TableCell label={t("Height")} value={item.ert} style="four" />
                    <TableCell label={t("Weight")} value={item.wazn} style="four" />
                </div>

                <div className="d-flex" dir="rtl">
                    <div style={{ fontWeight: 500 }}>{t("Information About Item")}</div>
                </div>
            </div>

            <div className="row mx-1 p-3">
                <div className="col-7">
                    <div
                        style={{
                            fontWeight: 500,
                            textAlign: "center",
                            marginBottom: "5px",
                        }}
                        className="blue"
                    >
                        {t("Previous sales")}
                    </div>
                    <DataGrid
                        dataSource={item.SaleTransactions}
                        showBorders={true}
                        height={"300px"}
                    >
                        <Column dataField="e_date" caption={t("Date")} alignment="right" />
                        <Column
                            dataField="name"
                            caption={t("Customer")}
                            alignment="right"
                        />
                        <Column dataField="price" caption={t("Price")} alignment="right" />
                    </DataGrid>
                </div>

                <div className="col-5">
                    <div
                        style={{
                            fontWeight: 500,
                            textAlign: "center",
                            marginBottom: "5px",
                        }}
                        className="blue"
                    >
                        {t("Quantity distribution in warehouses")}
                    </div>
                    <DataGrid
                        dataSource={itemStores}
                        showBorders={true}
                        height={"200px"}
                        selection={{ mode: "single" }}
                        onSelectionChanged={({ selectedRowsData }) => {
                            console.log(selectedRowsData[0]);
                            setMinimum(selectedRowsData[0]?._0);
                            setStore(selectedRowsData[0]);
                        }}
                    >
                        <Column dataField="m_name" caption={t("Stored")} alignment="right" />
                        <Column
                            dataField="kmea"
                            caption={t("Quantity")}
                            alignment="right"
                        />
                        <Column
                            dataField="_0"
                            caption={t("Redial Point")}
                            alignment="right"
                        />
                    </DataGrid>

                    <div className="mt-2" dir="rtl">
                        <div className="input-wrapper">
                            <div className="label blue" style={{ fontWeight: 650 }}>
                                {t("Redial Point")}
                            </div>
                            <NumberBox value={minimum} onValueChanged={handleChange} />
                        </div>
                        <Button
                            onClick={submitNumber}
                            text={t("Save")}
                            width={"100%"}
                            style={{ marginBottom: "20px" }}
                        />
                        <TableCell label={t("Item Location")} value={store?.locate} />
                    </div>
                </div>
            </div>

            <h6 dir="rtl" className="blue m-3 ">
                {t("Price")}
            </h6>
            <div className="border mx-3 p-3 triple">
                <TableCell label={t("sectoral price")} value={item.price} />
                <TableCell label={t("Wholesale price")} value={item.p_gmla} />
                <TableCell label={t("last purchase")} value={item.LastBuyPrice} />
                <TableCell
                    label={t("last local purchase")}
                    value={item.LastBuyPriceBySystemMoneyType}
                />
                <TableCell label={t("initial purchase")} value={150000} />
                <TableCell label={t("Total")} value={150000} />
            </div>

            <div className="my-2" dir="rtl">
                <h6 dir="rtl" className="blue m-3 ">
                    {t("Last transaction with the customer")}
                </h6>
                <div className="item-sales border mx-3 p-3">
                    <TableCell label={t("Price")} value={item.LastSalePrice} />
                    <TableCell label={t("Quantity")} value={item.LastSaleQuantity} />
                </div>
            </div>
        </div>
    );
};

export default ItemDetails;
