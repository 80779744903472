import React from "react";
import { TreeList, Selection, Editing } from "devextreme-react/tree-list";
import DataGrid, { Button, Column } from "devextreme-react/data-grid";
import { useTranslation } from "react-i18next";

const TreeListTable = ({
	dataSource = [],
	selection = false,
	colAttributes = [],
	height = 500,
	onRowInserting,
	onRowRemoving,
	allowUpdating = false,
	allowDeleting = false,
	allowAdding = false,
	keyExpr,
	parentIdExpr,
	buttonfunction,
}) => {
	const { t, i18n } = useTranslation();

	return (
		<div>
			<TreeList
				dataSource={dataSource}
				columnAutoWidth={true}
				wordWrapEnabled={true}
				height={height}
				showBorders={true}
				keyExpr={keyExpr}
				parentIdExpr={parentIdExpr}
				rtlEnabled={"rtl"}
				onRowInserting={onRowInserting}
				onRowRemoving={onRowRemoving}
			>
				<Editing
					allowAdding={allowAdding}
					allowUpdating={allowUpdating}
					allowDeleting={allowDeleting}
					onRowRemoving={onRowRemoving}
					useIcons={true}
					mode="row"
				/>

				{colAttributes?.map((ele) =>
					!ele.hide ? (
						<Column
							width={ele?.width}
							alignment={
								ele?.alignment ||
								(i18n.language === "ar" ? "right" : "left")
							}
							dataField={ele?.col}
							caption={
								i18n.language === "en"
									? ele.captionEn ?? ele.caption
									: ele.caption
							}
							dataType={ele.dataType ? ele.dataType : "text"}
						/>
					) : null
				)}
				<Column type="buttons" width={110}>
					<Button name="edit" />
					<Button name="delete" />

					<Button hint="Add" icon="plus" onClick={buttonfunction} />
				</Column>
			</TreeList>
		</div>
	);
};

export default React.memo(TreeListTable);
