import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { SingleCard } from "./layouts";
import {
  LoginForm,
  ResetPasswordForm,
  ChangePasswordForm,
  CreateAccountForm,
} from "./Components";
import Login from "./Pages/Login/Login";
import ResetPassword from "./Pages/ResetPassword";

export default function UnauthenticatedContent() {
  return (
    <Switch>
      <Route exact path="/login">
        {/* <SingleCard title="Sign In"> */}
        <Login />
        {/* </SingleCard> */}
      </Route>
      <Route exact path="/reset-password">
        <ResetPassword />
      </Route>
      <Redirect to={"/login"} />
    </Switch>
  );
}
