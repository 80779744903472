import { request } from "../../Services/CallAPI";
export const REPORTS = async (param, data) => {
  let config = {
    method: "post",
    url: `/Report/` + param,
    data: data,
  };

  return await request(config);
};
