import React from "react";
import "./Input.css";

import { useTranslation } from "react-i18next";

const Input = ({
  type,
  isRequired,
  label,
  labelId,
  value,
  onChange,
  name,
  error,
  onBlur,
}) => {
  const { t, i18n } = useTranslation();
  // add ar class to div.inputField to style arabic
  return (
    <>
      <div
        style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
        className="txt_field notCairo"
      >
        <input
          type={type}
          required
          name={name}
          value={value}
          onChange={onChange}
          autoFocus={true}
        />
        <label className="notCairo"> {t(label)}</label>
      </div>

      <label style={{ color: "#ff6666" }}>{error}</label>
    </>
  );
};

export default Input;
