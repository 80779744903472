// React
import React, { useState, useEffect, useCallback, useRef } from "react";
// Components
import SimpleDealEdit from "./SimpleDealEdit";
import { TextBox, SelectBox, CheckBox } from "../../../../Components/Inputs";
import ButtonRow from "../../../../Components/SharedComponents/buttonsRow";
// DevExpress
import notify from "devextreme/ui/notify";
import { Popup } from "devextreme-react/popup";
import { SelectBox as SelectExpress } from "devextreme-react";
import ScrollView from "devextreme-react/scroll-view";

// API
import {
  GET_FINANCIAL_TRANSACTIONS_TYPES,
  CHECK_FINANCIAL_TRANSACTIONSDATA,
  FINANCIAL_TRANSACTIONS,
} from "../../../../Services/ApiServices/Settings/SystemSettingsAPI";
import { useTranslation } from "react-i18next";

function SimpleDeal(props) {
  const { closeModal, handleShowSimpleDeal, simpleDealVisibility } = props;
  const { t, i18n } = useTranslation();
  // ============================================================================================================================
  // ================================================= Lists ====================================================================
  // ============================================================================================================================
  // Initial Data of the pop up
  const initialData = useRef({
    S1: "0",
    S2: "1",
    S_ReadOnly: false,
    byan_F: "",
    byanEn_F: "",
    byan_S: "",
    byanEn_S: "",
    hesab_static: "",
    id: "",
    name_hesab: "",
    name_moaamla: "",
    str_F: "",
    str_S: "",
  });

  // Right Side Initial Data
  const firstSideInitialData = useRef({
    customers_f: false,
    suppliers_f: false,
    storages_f: false,
    other_accounts_f: false,
    banks_f: false,
    employees_f: false,
    spendings_f: false,
    main_accounts_f: false,
    fixed_assests_f: false,
  });
  // Left Side Initial Data
  const secondSideInitialData = useRef({
    customers_s: false,
    suppliers_s: false,
    storages_s: false,
    other_accounts_s: false,
    banks_s: false,
    employees_s: false,
    spendings_s: false,
    main_accounts_s: false,
    fixed_assests_s: false,
  });

  // List of first or second part
  const partyList = useRef([
    { id: "0", description: t("Debit") },
    { id: "1", description: t("Creditor") },
  ]);

  // ============================================================================================================================
  // ================================================= State ====================================================================
  // ============================================================================================================================

  // Show or hide Edit Delete Modal of this page.
  const [editDeleteVisibility, setEditDeleteVisibility] = useState(false);
  // set selected item of edit modal
  const [selectedItem, setSelectedItem] = useState("");
  // set main modal data
  const [data, setData] = useState(initialData.current);
  // set data of selected item from edit modal
  const [currentEditData, setCurrentEditData] = useState(initialData.current);
  // Setting First Part data or Right part Data
  const [firstSideData, setFirstSideData] = useState(
    firstSideInitialData.current
  );
  // Setting Second Part data or left part Data
  const [secondSideData, setSecondSideData] = useState(
    secondSideInitialData.current
  );
  // Know if the button we clicked is edit or delete
  const [buttonType, setButtonType] = useState("");

  // ============================================================================================================================
  // ================================================= Effects ==================================================================
  // ============================================================================================================================

  // First render get data function
  useEffect(() => {
    GET_FINANCIAL_TRANSACTIONS_TYPES()
      .then((res) => {
        setData(res);
      })
      .catch((err) => console.log(err));
  }, []);

  // To change Debitor and Creditor select box depending on changing in right side select box.
  useEffect(() => {
    parseInt(currentEditData.S1) === 0
      ? setCurrentEditData({ ...currentEditData, S2: "1" })
      : setCurrentEditData({ ...currentEditData, S2: "0" });
  }, [currentEditData.S1]);

  // To change Debitor and Creditor select box depending on changing in lef side select box.
  useEffect(() => {
    parseInt(currentEditData.S2) === 0
      ? setCurrentEditData({ ...currentEditData, S1: "1" })
      : setCurrentEditData({ ...currentEditData, S1: "0" });
  }, [currentEditData.S2]);

  useEffect(() => {
    var firstSide = "(" + Object.values(firstSideData).toString() + ")";
    var secondSide = "(" + Object.values(secondSideData).toString() + ")";
    setCurrentEditData({
      ...currentEditData,
      str_F: firstSide,
      str_S: secondSide,
    });
  }, [firstSideData, secondSideData]);
  useEffect(() => {
    // console.log(secondSideData);
  }, [secondSideData]);
  // Set data to form after choosing edit list item
  useEffect(() => {
    if (selectedItem !== "") {
      var item = data.find((element) => element.name_moaamla === selectedItem);

      setCurrentEditData(item);

      var firstSide = item.str_F.replace("(", "").replace(")", "").split(",");
      var secondSide = item.str_S.replace("(", "").replace(")", "").split(",");
      var firstSideKeys = Object.keys(firstSideInitialData.current);
      var secondSideKeys = Object.keys(secondSideInitialData.current);
      let firstSideObj = {};
      let secondSideObj = {};
      /* console.log(Object.keys(secondSideData).map(function(key, index) {
		secondSideData[key]=secondSide[];
	  }))*/
      for (let i = 0; i < firstSideKeys.length; i++) {
        firstSideObj[firstSideKeys[i]] = firstSide[i].toLowerCase() === "true";
        secondSideObj[secondSideKeys[i]] =
          secondSide[i].toLowerCase() === "true";
      }

      // Setting data after submitting any edit list option from edit list
      setCurrentEditData({
        ...item,
      });

      setFirstSideData({ ...firstSideData, ...firstSideObj });
      setSecondSideData({ ...secondSideData, ...secondSideObj });
    }
  }, [selectedItem]);

  // ============================================================================================================================
  // ================================================= Handelers ====================================================================
  // ============================================================================================================================

  // Handle edit and delete
  let handleEditDelete = useCallback(
    async (title) => {
      setButtonType(title);
      if (editDeleteVisibility) {
        setEditDeleteVisibility(!editDeleteVisibility);
        setSelectedItem("");
      } else {
        setEditDeleteVisibility(!editDeleteVisibility);
        setData([]);
        await GET_FINANCIAL_TRANSACTIONS_TYPES().then((res) => {
          setData(res);
        });
      }
    },
    [editDeleteVisibility]
  );

  // Handle change
  let handleChange = useCallback(({ name, value }) => {
    if (name.includes("_f")) {
      setFirstSideData((prev) => ({ ...prev, [name]: value }));
    } else if (name.includes("_s")) {
      setSecondSideData((prev) => ({ ...prev, [name]: value }));
    } else {
      setCurrentEditData((prev) => ({ ...prev, [name]: value }));
    }
  }, []);

  // Handle reset and reset general function
  let handleReset = useCallback(() => {
    setCurrentEditData(initialData.current);
    setFirstSideData({ ...firstSideInitialData.current });
    setSecondSideData({ ...secondSideInitialData.current });
    setData([]);
  }, []);

  // handling submit and update
  let handleSubmit = useCallback(() => {
    // Check object to check if name_moaamla has value
    var CheckDataObj = {
      ID: currentEditData["ID"],
      CheckData: { name_moaamla: currentEditData["name_moaamla"] },
    };

    CHECK_FINANCIAL_TRANSACTIONSDATA(CheckDataObj)
      .then(async (res) => {
        if (res.Check) {
          console.log("1");
          FINANCIAL_TRANSACTIONS(currentEditData)
            .then((res) => {
              notify(
                { message: "تم التحديث بنجاح", width: 600 },
                "success",
                3000
              );
              console.log("2");

              //   handleReset();
            })
            .catch((err) => console.log(err));
        } else {
          if (currentEditData["name_moaamla"] == "") {
            notify(
              {
                message: "يجب تسجيل قيمة في بيان المعاملة",
                width: 600,
              },
              "error",
              3000
            );
          } else {
            notify(
              {
                message: "سبق استخدام نفس القيمة ل بيان المعاملة",
                width: 600,
              },
              "error",
              3000
            );
          }
        }
      })
      .catch((err) => console.log(err));
  }, [currentEditData]);

  let handleSettingSelectedItem = useCallback((data) => {
    setSelectedItem(data);
  }, []);
  let GridDisplayCols = "col-lg-4 col-md-6 col-sm-12";
  return (
    <Popup
      maxWidth={"70%"}
      title={t("Create a financial transaction")}
      minWidth={250}
      // minHeight={"50%"}
      showTitle={true}
      dragEnabled={false}
      closeOnOutsideClick={true}
      visible={simpleDealVisibility}
      onHiding={() => {
        handleShowSimpleDeal();
        handleReset();
      }}
    >
      <ScrollView>
        <div dir="auto">
          <div className="row">
            <div className={GridDisplayCols}>
              <TextBox
                label={t("Transaction statement")}
                required={false}
                name="name_moaamla"
                value={currentEditData["name_moaamla"]}
                handleChange={handleChange}
              />
            </div>

            <div className={GridDisplayCols}>
              <SelectBox
                label={t("primary account")}
                value={currentEditData["ss"]}
                name="ss"
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className="d-flex flex-column">
            <h4 style={{ padding: "5px" }}>{t("First Side")}</h4>
            <div className="d-flex flex-column justify-content-start">
              <div className="row">
                <div className={GridDisplayCols}>
                  <TextBox
                    label={t("First Side")}
                    required={false}
                    value={currentEditData["byan_F"]}
                    name="byan_F"
                    handleChange={handleChange}
                  />
                </div>
                <div className={GridDisplayCols}>
                  <TextBox
                    label={t("First Side English")}
                    required={false}
                    value={currentEditData["byanEn_F"]}
                    name="byanEn_F"
                    handleChange={handleChange}
                  />
                </div>

                <div className={GridDisplayCols}>
                  <SelectBox
                    label={t("First Side Type")}
                    dataSource={partyList.current}
                    keys={{ id: "id", name: "description" }}
                    name="S2"
                    defaultValue={partyList.current[1].id}
                    value={currentEditData["S2"].toString()}
                    handleChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className={GridDisplayCols}>
                  <CheckBox
                    label={t("Customers")}
                    value={firstSideData["customers_f"]}
                    name="customers_f"
                    handleChange={handleChange}
                    required={false}
                  />
                </div>
                <div className={GridDisplayCols}>
                  <CheckBox
                    label={t("Suppliers")}
                    value={firstSideData["suppliers_f"]}
                    name="suppliers_f"
                    handleChange={handleChange}
                    required={false}
                  />
                </div>
                <div className={GridDisplayCols}>
                  <CheckBox
                    label={t("Safes")}
                    value={firstSideData["storages_f"]}
                    name="storages_f"
                    handleChange={handleChange}
                    required={false}
                  />
                </div>
                <div className={GridDisplayCols}>
                  <CheckBox
                    label={t("Other accounts")}
                    value={firstSideData["other_accounts_f"]}
                    name="other_accounts_f"
                    handleChange={handleChange}
                    required={false}
                  />
                </div>
                <div className={GridDisplayCols}>
                  <CheckBox
                    label={t("Banks")}
                    value={firstSideData["banks_f"]}
                    name="banks_f"
                    employees_f
                    required={false}
                    handleChange={handleChange}
                  />
                </div>
                <div className={GridDisplayCols}>
                  <CheckBox
                    label={t("Employees")}
                    value={firstSideData["employees_f"]}
                    name="employees_f"
                    handleChange={handleChange}
                    required={false}
                  />
                </div>
                <div className={GridDisplayCols}>
                  <CheckBox
                    label={t("Expenses")}
                    value={firstSideData["spendings_f"]}
                    name="spendings_f"
                    handleChange={handleChange}
                    required={false}
                  />
                </div>
                <div className={GridDisplayCols}>
                  <CheckBox
                    label={t("Basic Accounts")}
                    value={firstSideData["main_accounts_f"]}
                    name="main_accounts_f"
                    handleChange={handleChange}
                    required={false}
                  />
                </div>
                <div className={GridDisplayCols}>
                  <CheckBox
                    label={t("Fixed assets")}
                    value={firstSideData["fixed_assests_f"]}
                    name="fixed_assests_f"
                    handleChange={handleChange}
                    required={false}
                  />
                </div>
              </div>
            </div>
            <h4 style={{ padding: "5px" }}>{t("Second Side")}</h4>
            <div className="d-flex flex-column justify-content-start">
              <div className="row">
                <div className={GridDisplayCols}>
                  <TextBox
                    label={t("Second Side")}
                    value={currentEditData["byan_S"]}
                    name="byan_S"
                    handleChange={handleChange}
                    required={false}
                  />
                </div>

                <div className={GridDisplayCols}>
                  <TextBox
                    label={t("Second Side English")}
                    value={currentEditData["byanEn_S"]}
                    name="byanEn_S"
                    handleChange={handleChange}
                    required={false}
                  />
                </div>

                <div className={GridDisplayCols}>
                  <SelectBox
                    label={t("Second Side Type")}
                    dataSource={partyList.current}
                    keys={{ id: "id", name: "description" }}
                    name="S1"
                    defaultValue={partyList.current[1].id}
                    value={currentEditData["S1"].toString()}
                    handleChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className={GridDisplayCols}>
                  <CheckBox
                    label={t("Customer")}
                    value={secondSideData["customers_s"]}
                    name="customers_s"
                    handleChange={handleChange}
                    required={false}
                  />
                </div>
                <div className={GridDisplayCols}>
                  <CheckBox
                    label={t("Suppliers")}
                    value={secondSideData["suppliers_s"]}
                    name="suppliers_s"
                    handleChange={handleChange}
                    required={false}
                  />
                </div>
                <div className={GridDisplayCols}>
                  <CheckBox
                    label={t("Safes")}
                    value={secondSideData["storages_s"]}
                    name="storages_s"
                    handleChange={handleChange}
                    required={false}
                  />
                </div>
                <div className={GridDisplayCols}>
                  <CheckBox
                    label={t("Other accounts")}
                    value={secondSideData?.other_accounts_s}
                    name="other_accounts_s"
                    handleChange={handleChange}
                    required={false}
                  />
                </div>
                <div className={GridDisplayCols}>
                  <CheckBox
                    label={t("Banks")}
                    value={secondSideData["banks_s"]}
                    name="banks_s"
                    employees_f
                    required={false}
                    handleChange={handleChange}
                  />
                </div>
                <div className={GridDisplayCols}>
                  <CheckBox
                    label={t("Employees")}
                    value={secondSideData["employees_s"]}
                    name="employees_s"
                    handleChange={handleChange}
                    required={false}
                  />
                </div>
                <div className={GridDisplayCols}>
                  <CheckBox
                    label={t("Expenses")}
                    value={secondSideData["spendings_s"]}
                    name="spendings_s"
                    handleChange={handleChange}
                    required={false}
                  />
                </div>
                <div className={GridDisplayCols}>
                  <CheckBox
                    label={t("Basic Accounts")}
                    value={secondSideData["main_accounts_s"]}
                    name="main_accounts_s"
                    handleChange={handleChange}
                    required={false}
                  />
                </div>
                <div className={GridDisplayCols}>
                  <CheckBox
                    label={t("Fixed assets")}
                    value={secondSideData["fixed_assests_s"]}
                    name="fixed_assests_s"
                    handleChange={handleChange}
                    required={false}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 " style={{ padding: "20px" }}>
            <ButtonRow
              onEdit={handleEditDelete}
              onNew={handleReset}
              onUndo={handleReset}
              onSubmit={handleSubmit}
              onDelete={handleEditDelete}
              onExit={closeModal}
              isSimilar={false}
            />
          </div>
          {/* Edit Popup */}
          <Popup
            maxWidth={"30%"}
            minWidth={250}
            minHeight={"50%"}
            closeOnOutsideClick={true}
            visible={editDeleteVisibility}
            onHiding={handleEditDelete}
          >
            <ScrollView>
              <SimpleDealEdit
                selectedItem={handleSettingSelectedItem}
                close={handleEditDelete}
                list={data}
                statue={buttonType}
              />
            </ScrollView>
          </Popup>
        </div>
      </ScrollView>
    </Popup>
  );
}

export default React.memo(SimpleDeal);
