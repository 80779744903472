export default {
  fetchingTime: "10",
  apiEndPoint: "https://erp.almedadsoft.com/mdfunctions",
  apiEndPointDemo: "https://api.almedadsoft.com",
  apiEndPointDemo2: "http://localhost:59861",
  localStorageTokenKey: "GYq4vDq/EmeL4OvWwPLzzN0DwkU6ejz5ZFq1BI1MUho=",
  localStorageReadIdKey: "QwWEt1EIVYNDwQtrLp/tyk9VMEuhc+nyDJ1wwKQjLfY=",
  localStorageCurrencyKey:
    "3AC1A9EC4FA7A9FA612A30803F3D88FA479A45431F644190EBCC18D3EAAFDB3A",
  maxFetchTime: 5,
};
