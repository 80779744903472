export default {
  LookUpsList: {
    الجنسيــــــــــــــــــــــات: "nationality",
    "تصنيــــف الـــموظفيــــن": "employeetypes",
    "أنـــــــواع العـــــــــلاوات": "alawat",
    "أنـــــــواع الخصميـــــــات": "ksmeat",
    المصــــــــــــــــــــــــارف: "bank",
    "الأصـــــــول الثــــابتـــة": "typ_osol",
    العمــــــــــــــــــــــــلات: "OMLAT",
    الوحــــــــــــــــــــــــدات: "units",
    "أنـــــــــواع الأصنـــــــاف": "types",
    "مجموعـــــــة العمــــــــل": "emp_group",
    "أنــــــواع الأجهزة": "machine_type",
  },
};
